import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { CaseConfigProfileList, CaseGlobalProfile, CasesProfileList } from "../../entities/cases";
import { addNotification } from "../Notification/notificateStore";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

type ProfilePromise = {
  list: CasesProfileList[],
  count: number,
}

export const getCaseProfiles = createAsyncThunk(
  `case/post-bonus-localizations-by-id`,
  async (): Promise<CasesProfileList[]> => {
    const req = await agent.get(`${DEFAULT_URL}/profile/list`);
    if (req.ok) {
      const res = await req.json() as ProfilePromise;
      return res.list;
    }
    return []
  }
)
export const postProfile = createAsyncThunk(
  'case/post-profile',
  async (data: CaseGlobalProfile, thunkApi) => {
    try {
      const req = await agent.post(`${DEFAULT_URL}/profile/`, {
        body: JSON.stringify(data),
      });
      if (req.ok) {
        const res = await req.json() as { id: number }
        thunkApi.dispatch(getCaseProfiles());
        return res.id;
      }
      if (!req.ok) {
        const result = await req.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;
      }
    } catch (error) {
      throw error;
    }

  }
)
// [POST]/organization/game/case/profile

export const getProfileById = createAsyncThunk(
  'case/get-profile-by-id',
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/profile/${id}`);
    if (req.ok) {
      const res = req.json();
      return res;
    }
  }
)
// [PATCH]/organization/game/case/profile/{profileId}
export const patchProfile = createAsyncThunk(
  'case/patch-profile',
  async (data: CaseGlobalProfile, thunkApi) => {
    try {
      const req = await agent.patch(`${DEFAULT_URL}/profile/${data.id}`, {
        body: JSON.stringify(data),
      });
      if (req.ok) {
        thunkApi.dispatch(getCaseProfiles());
        thunkApi.dispatch(getProfileById(data.id))
      }
      if (!req.ok) {
        const result = await req.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;
      }
    } catch (error) {
      throw error;
    }

  }
)

// [GET]/organization/game/case/profile/config/list
export const getProfileConfige = createAsyncThunk(
  'case/get-profile-config',
  async (id: number) => {
    const query = encodeGetParams({ profileId: id, page: 1, limit: 250 })
    const req = await agent.get(`${DEFAULT_URL}/profile/config/list${query}`)
    if (req.ok) {
      const res = await req.json() as { list: CaseConfigProfileList[] }
      return res.list;
    }
    return [];
  }
)
// [POST]/organization/game/case/profile/config
export const postProfileConfige = createAsyncThunk(
  'case/post-profile-config',
  async (props: { body: any, id: number }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/profile/config`, {
      body: JSON.stringify(props.body)
    })
    if (req.ok) {
      thunksApi.dispatch(getProfileConfige(props.id))
    }
  }
)


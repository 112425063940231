import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  CaseBonusList,
  CaseConfigProfileList,
  CaseGlobal,
  CaseGlobalBonus,
  CaseGlobalProfile,
  CaseGlobalRequest,
  CaseHowToWinTranslation,
  CasePricingTranslation,
  CasesDatabaseList,
  CasesProfileList,
  CaseWinrateResponse,
  InitialValueProps,
  ReqBonusLocal,
  ResponsePostContentLangCase,
} from "../../entities/cases";
import { RootState } from "..";
import {
  deleteBonusCase,
  getBonusesCase,
  getCaseBonusById,
  getCaseBonusLocalization,
  patchBonusCase,
  patchCaseBonusLocalization,
  postBonusCase,
  postCaseBonusLocalization,
} from "./bonusThunks";
import {
  getCaseProfiles,
  getProfileById,
  getProfileConfige,
  postProfile,
  postProfileConfige,
  patchProfile
} from "./profileThunks";
import {
  getCaseContent,
  getCaseDatabases,
  getCaseGlobal,
  getElementOrder,
  getHowToWin,
  getPopup,
  getPricing,
  getWinrate,
  patchCaseContentTranslation,
  patchCaseGlobal,
  patchElementOrder,
  patchHowToWinTranslation,
  patchPopupSettings,
  patchPopupTranslationById,
  patchPricing,
  patchPricingTranslation,
  patchWinrate,
  postCaseContent,
  postCaseContentTranslation,
  postCaseGlobal,
  postElementOrder,
  postHowToWinTranslation,
  postPopupSettings,
  postPopupTranslationById,
  postPricing,
  postPricingTranslation,
  postWinrate,
} from "./databaseThunks";
import { ElementOrder, HowToWinGlobal, ICountrySettings, JackpotDatabaseContent, JackpotPullType, PopupSettingsForm, ResponsePopupLangs, ResponsePopupSettings } from "../../entities/jackpotGame";
import { parseResponsePricingCase } from "../../middleware/case/parsePricingData";

interface CaseState {
  loading: {
    bonus: boolean;
    profile: boolean;
    database: boolean;
  };
  saveLoading: boolean;
  list: {
    bonuses: CaseBonusList[];
    profiles: CasesProfileList[];
    databases: CasesDatabaseList[];
  };
  caseGlobalSettings?: CaseGlobal;
  contentLocalization: {
    global?: JackpotDatabaseContent;
    langs?: ResponsePostContentLangCase[];
  };
  pricing: {
    global?: InitialValueProps,
    langs?: CasePricingTranslation[],
    paidCountrySettings: ICountrySettings[]
  }
  popupSettings: {
    global?: PopupSettingsForm;
    langs?: ResponsePopupLangs[];
  };
  winRate?: CaseWinrateResponse[]
  howToWin: {
    global?: HowToWinGlobal;
    langs?: CaseHowToWinTranslation[];
  };
  pageElementOrder?: ElementOrder[];
  bonus?: CaseGlobalBonus;
  bonusLocalization?: ReqBonusLocal[];
  profile?: CaseGlobalProfile;
  profileCaseConfige: JackpotPullType[] | [];
}

const initialState: CaseState = {
  saveLoading: false,
  loading: {
    bonus: false,
    profile: false,
    database: false,
  },
  list: {
    bonuses: [],
    profiles: [],
    databases: [],
  },
  contentLocalization: {},
  howToWin: {},
  popupSettings: {},
  pricing: {
    paidCountrySettings: []
  },
  profileCaseConfige: []
};

const addLoadingCases = (
  builder: ActionReducerMapBuilder<CaseState>,
  action: any
) => {
  builder.addCase(action.pending, (state: CaseState) => {
    state.saveLoading = true;
  });

  builder.addCase(action.fulfilled, (state: CaseState) => {
    state.saveLoading = false;
  });
  builder.addCase(action.rejected, (state: CaseState) => {
    state.saveLoading = false;
  })
};

export const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    clearEditBonus: (state) => {
      state.bonus = undefined;
      state.bonusLocalization = undefined;
    },
    clearEditProfile: (state) => {
      state.profile = undefined;
    },
    clearEditCase: (state) => {
      state.caseGlobalSettings = undefined;
      state.contentLocalization = {
        langs: undefined,
        global: undefined,
      }
      state.pricing = {
        global: undefined,
        langs: undefined,
        paidCountrySettings: [],
      }
      state.popupSettings = {
        langs: undefined,
        global: undefined,
      };
      state.winRate = undefined;
      state.howToWin = {
        langs: undefined,
        global: undefined,
      };

      state.pageElementOrder = undefined;
    },
  },
  extraReducers: (builder) => {
    // Bonuses
    builder.addCase(getBonusesCase.pending, (state) => {
      state.loading.bonus = true;
    });
    builder.addCase(getBonusesCase.fulfilled, (state, action) => {
      state.loading.bonus = false;
      state.list.bonuses = action.payload.sort((a, b) => a.id - b.id);
    });
    // getCaseBonusById
    builder.addCase(getCaseBonusById.pending, (state) => {
      state.loading.bonus = true;
    });
    builder.addCase(getCaseBonusById.fulfilled, (state, action) => {
      if (action.payload) state.bonus = action.payload;
      state.loading.bonus = false;
    });
    // getCaseBonusLocalization
    builder.addCase(getCaseBonusLocalization.pending, (state) => {
      state.loading.bonus = true;
    });
    builder.addCase(getCaseBonusLocalization.fulfilled, (state, action) => {
      state.loading.bonus = false;
      state.bonusLocalization = action.payload;
    });
    builder.addCase(getCaseBonusLocalization.rejected, (state) => {
      state.loading.bonus = false;
      state.bonusLocalization = undefined;
    });

    // Profiles
    builder.addCase(getCaseProfiles.pending, (state) => {
      state.loading.profile = true;
    });
    builder.addCase(getCaseProfiles.fulfilled, (state, action) => {
      state.loading.profile = false;
      state.list.profiles = action.payload.sort((a, b) => a.id - b.id);
    });
    // getProfileById
    builder.addCase(getProfileById.pending, (state) => {
      state.loading.profile = true;
    });
    builder.addCase(getProfileById.fulfilled, (state, action) => {
      const result = {
        ...action.payload,
        triggerId: action.payload.trigger.id,
      };
      state.profile = result;
      state.loading.profile = false;
    });

    // Databases
    builder.addCase(getCaseDatabases.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getCaseDatabases.fulfilled, (state, action) => {
      state.loading.database = false;
      state.list.databases = action.payload.sort((a, b) => a.id - b.id);
    });
    // getCaseGlobal
    builder.addCase(getCaseGlobal.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getCaseGlobal.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload as CaseGlobalRequest;
        const { config } = data;
        state.caseGlobalSettings = {
          id: data.id,
          name: data.name,
          primaryTags: config.primaryTags,
          langs: data.langs,
          colorPicker: config.colorPicker,
          cahortList: config.cahortList,
          bonusIds: config.bonusIds,
          caseConfigurationNotes: config.caseConfigurationNotes,
          fakeCaseOpeningStats:
            config.fakeCaseOpeningStats === "Yes" ? true : false,
          caseOpeningStats: config.caseOpeningStats,
        };
      }
      state.loading.database = false;
    });
    // getCaseContent
    builder.addCase(getCaseContent.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getCaseContent.fulfilled, (state, action) => {
      if (action.payload) {
        const { globalSettings, langs } = action.payload as {
          langs: ResponsePostContentLangCase[];
          globalSettings: JackpotDatabaseContent;
        };
        if (globalSettings && Object.keys(globalSettings).length > 0) {
          state.contentLocalization.global = globalSettings;
        } else {
          state.contentLocalization.global = undefined;
        }
        if (langs.length > 0) {
          state.contentLocalization.langs = langs;
        } else {
          state.contentLocalization.langs = undefined;
        }
      }
      state.loading.database = false;
    });
    builder.addCase(getCaseContent.rejected, (state) => {
      state.contentLocalization.global = undefined;
      state.contentLocalization.langs = undefined;
      state.loading.database = false;
    });
    // getHowToWin
    builder.addCase(getHowToWin.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getHowToWin.fulfilled, (state, action) => {
      state.loading.database = false;
      if (action.payload) {
        const { globalSettings, langs } = action.payload;
        if (
          (globalSettings && Object.keys(globalSettings).length > 0) ||
          (!globalSettings.instructionBanner && !globalSettings.numberOfRules)
        ) {
          if (
            !globalSettings.instructionBanner &&
            !globalSettings.numberOfRules
          ) {
            state.howToWin.global = undefined;
          } else {
            state.howToWin.global = globalSettings;
          }
        } else {
          state.howToWin.global = undefined;
        }
        if (langs.length > 0) {
          state.howToWin.langs = langs;
        } else {
          state.howToWin.langs = undefined;
        }
      }
    });
    // getElementOrder
    builder.addCase(getElementOrder.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getElementOrder.fulfilled, (state, action) => {
      state.loading.database = false;
      if (action.payload) {
        state.pageElementOrder = action.payload;
      } else {
        state.pageElementOrder = [
          { position: 0, title: "case_description_first_block" },
          { position: 1, title: "case_description_second_block" },
          { position: 2, title: "case_description_third_block" },
          { position: 3, title: "how_to_win" },
          { position: 4, title: "bonuses" },
        ];
      }
    });
    // getPopup
    builder.addCase(getPopup.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getPopup.fulfilled, (state, action) => {
      if (action.payload) {
        const { globalSettings, langs } = action.payload as ResponsePopupSettings
        if (globalSettings && Object.keys(globalSettings).length > 0) {
          state.popupSettings.global = globalSettings;
        } else {
          state.popupSettings.global = undefined;
        }
        if (langs.length > 0) {
          state.popupSettings.langs = langs;
        } else {
          state.popupSettings.langs = undefined;
        }
      }
      state.loading.database = false;
    });
    builder.addCase(getPopup.rejected, (state) => {
      state.popupSettings.global = undefined;
      state.popupSettings.langs = undefined;
      state.loading.database = false;
    });
    // getWinrate
    builder.addCase(getWinrate.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getWinrate.fulfilled, (state, action) => {
      if (action.payload) {
        state.winRate = action.payload;
      }
      state.loading.database = false;
    });
    builder.addCase(getWinrate.rejected, (state) => {
      state.winRate = undefined;
      state.loading.database = false;
    });
    // getPricing
    builder.addCase(getPricing.pending, (state) => {
      state.loading.database = true;
    });
    builder.addCase(getPricing.fulfilled, (state, action) => {
      if (action.payload) {
        const { lang, globalSettings } = action.payload;
        const { caseType, progressBarSettings } = globalSettings;
        if (caseType && progressBarSettings) {
          const _caseType = caseType.caseType === "Paid" ? true : false;
          const progress =
            progressBarSettings.progressBarType === "Numerical" ? true : false;
          state.pricing.global = {
            caseSettings: {
              caseType: _caseType,
              settings: {
                ...parseResponsePricingCase(caseType),
              },
            },
            progressBarSettings: {
              porgressValue: progressBarSettings.progressValue,
              progressBarSettingsActive: progressBarSettings.enabled,
              progressBarType: progress,
            },
          };
          if (
            caseType.countryAndRegionSettings &&
            caseType.countryAndRegionSettings.length > 0
          ) {
            state.pricing.paidCountrySettings = caseType.countryAndRegionSettings;
          }
        } else {
          state.pricing.global = undefined;
          state.pricing.paidCountrySettings = [];
        }
        if (lang.length > 0) {
          state.pricing.langs = lang;
        } else {
          state.pricing.langs = undefined;
        }
      }
      state.loading.database = false;
    });
    builder.addCase(getPricing.rejected, (state) => {
      state.winRate = undefined;
      state.loading.database = false;
    });
    // getProfileConfige
    builder.addCase(getProfileConfige.pending, (state) => {
      state.loading.profile = true;
    })
    builder.addCase(getProfileConfige.fulfilled, (state, action) => {
      if (action.payload) {
        const array = action.payload as CaseConfigProfileList[];
        if (array.length > 0) {
          const result: JackpotPullType[] = array.map((item, index) => ({
            index,
            langs: [],
            prizeId: item.gameEntity.id,
          }));
          state.profileCaseConfige = result;
        }
      }
      state.loading.profile = false;
    })
    builder.addCase(getProfileConfige.rejected, (state) => {
      state.loading.profile = false;
    })

    const saveActions = [
      postProfile,
      patchProfile,
      postProfileConfige,
      postBonusCase,
      patchBonusCase,
      deleteBonusCase,
      postCaseGlobal,
      patchCaseGlobal,
      postCaseBonusLocalization,
      patchCaseBonusLocalization,
      postPricing,
      postPricingTranslation,
      patchPricing,
      patchPricingTranslation,
      postElementOrder,
      patchElementOrder,
      postCaseContent,
      postCaseContentTranslation,
      patchCaseContentTranslation,
      postPopupSettings,
      patchPopupSettings,
      postPopupTranslationById,
      patchPopupTranslationById,
      patchWinrate,
      postWinrate,
      patchHowToWinTranslation,
      postHowToWinTranslation,
    ];
    saveActions.forEach((action) => addLoadingCases(builder, action));
  },
});

export const { clearEditBonus, clearEditProfile, clearEditCase } = caseSlice.actions;

export const selectCaseState = (state: RootState) => state.case;
export const selectCaseLoading = (state: RootState) => state.case.loading;
export const selectCaseLists = (state: RootState) => state.case.list;

export default caseSlice.reducer;
